import en from './locales/en';
import vi from './locales/vi';

export const AppLocale = {
  vi: {
    locale: 'vi',
    messages: vi,
  },
  en: {
    locale: 'en',
    messages: en,
  },
};
